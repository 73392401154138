var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-help" },
    [
      _vm.list.length
        ? [
            _c("div", { staticClass: "c-section c-section--mb24" }, [
              _c("h3", { staticClass: "c-title6" }, [_vm._v("カテゴリ")]),
              _c(
                "div",
                { staticClass: "p-help__categories" },
                _vm._l(_vm.categories, function (category) {
                  return _c("Button", {
                    directives: [
                      {
                        name: "scroll-to",
                        rawName: "v-scroll-to",
                        value: "#js-category-" + category.id,
                        expression: "`#js-category-${category.id}`",
                      },
                    ],
                    key: category.id,
                    attrs: {
                      "style-type": "quaternary",
                      bordered: "",
                      flat: "",
                      action: "",
                      size: "xs",
                    },
                    domProps: { textContent: _vm._s(category.name) },
                  })
                }),
                1
              ),
            ]),
            _vm._l(_vm.list, function (category) {
              return _c(
                "div",
                {
                  key: category.id,
                  staticClass:
                    "c-section c-section--fluid c-section--white01Bg c-section--mb24",
                  attrs: { id: "js-category-" + category.id },
                },
                [
                  _c(
                    "div",
                    { staticClass: "c-section__inner" },
                    [
                      _c("h2", { staticClass: "c-title2" }, [
                        _vm._v(_vm._s(category.name)),
                      ]),
                      _c(
                        "ExpansionPanels",
                        _vm._l(category.questions, function (item) {
                          return _c("ExpansionPanel", {
                            key: item.id,
                            attrs: {
                              header: "Q. " + item.question,
                              content: "A. " + item.answer,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
          ]
        : !_vm.$_loading_state
        ? _c("Empty")
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }