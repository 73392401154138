<template>
  <div class="p-help">
    <template v-if="list.length">
      <div class="c-section c-section--mb24">
        <h3 class="c-title6">カテゴリ</h3>
        <div class="p-help__categories">
          <Button
            v-for="category in categories"
            :key="category.id"
            v-scroll-to="`#js-category-${category.id}`"
            style-type="quaternary"
            bordered
            flat
            action
            size="xs"
            v-text="category.name" />
        </div>
      </div>

      <div
        v-for="category in list"
        :key="category.id"
        :id="`js-category-${category.id}`"
        class="c-section c-section--fluid c-section--white01Bg c-section--mb24">
        <div class="c-section__inner">
          <h2 class="c-title2">{{ category.name }}</h2>
          <ExpansionPanels>
            <ExpansionPanel
              v-for="item in category.questions"
              :key="item.id"
              :header="`Q. ${item.question}`"
              :content="`A. ${item.answer}`" />
          </ExpansionPanels>
        </div>
      </div>
    </template>

    <Empty v-else-if="!$_loading_state" />
  </div>
</template>
<script>
import { SystemDialogMessage } from '@/constants/enums';

export default {
  data: () => ({
    categories: [],
    list: []
  }),

  async created() {
    await this.fetchList();
  },

  methods: {
    async fetchList() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories('help').getList();
        this.categories.push(...data.category);
        this.list.push(...data.list);
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    }
  }
};
</script>
